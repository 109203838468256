* {
  font-family: "Livvic", sans-serif;
  box-sizing: border-box;
  outline: none;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

body {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  font-size: 1rem; /* Define o tamanho de fonte padrão */
}

html {
  /* Aumenta em 20% em relação ao tamanho padrão */
  font-size: 1.2rem;
}

#root {
  height: 100%;
}

table {
  margin-top: 20px;
}

th {
  font-size: 11px !important;
  color: black !important;
}

.home-content-sm__chart {
  width: 100% !important;
  margin-left: -20px !important;
}

@media (max-width: 641) {
  h5 {
    font-size: 18px !important;
  }
}

.a {
  text-decoration: none;
  color: #6c757d !important;
}

.a:visited {
  text-decoration: none;
  background-color: transparent;
  color: #6c757d !important;
}

.login-container-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1%;
  margin-top: -3%;
}

#login-input {
  font-family: "Livvic", sans-serif;
}

#outlined-adornment-password {
  font-family: "Livvic", sans-serif;
}

#list-item-text-menu {
  margin-left: -25px;
}

#login-text-info {
  font-size: 2.5rem;
  font-weight: 800;
  text-transform: uppercase;
  font-family: unset;
  font-family: "Livvic", sans-serif;
}

.container-login {
  height: 100%;
  width: 100%;
}

.container-login-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-between; */
  padding-left: 10%;
}

.text-muted {
  font-size: 12.5px;
  color: #1b301b;
}

#head-user {
  font-family: "Livvic", sans-serif;
}
#name_user {
  font-family: "Livvic", sans-serif;
  font-size: 18px;
  color: #1b301b;
}
#menu_user {
  padding: 0px;
  margin: 0px;
  margin-right: 80px;
  font-family: "Livvic", sans-serif;
  font-size: 14px;
  color: #1b301b;
}

#table_Cell {
  padding: 0;
  margin: 0;
  font-size: 18px;
  text-transform: lowercase;
  font-family: "Livvic", sans-serif;
  color: #006660;
}
#table_Cell_Col {
  margin: 0;
  font-size: 13px;
  font-family: "Livvic", sans-serif;
}
#inputLabel {
  margin: 0;
  font-size: 13px;
  font-family: "Livvic", sans-serif;
}
#text_Card_Item {
  margin: 0;
  font-size: 15px;
  color: #1b301b;
  font-family: "Livvic", sans-serif;
  font-weight: "bold";
}
/*Small devices (landscape phones, 576px and up)*/
@media (max-width: 641px) {
  #login-text-info {
    font-size: 2rem;
    font-weight: bolder;
    margin-top: 20px;
  }

  #compute-image {
    display: none;
  }

  .text-muted {
    display: none;
  }

  /* .container-login-form {
    align-items: flex-start;
  } */

  .paperHomeInfo {
    max-height: 200px;
    margin: 0;
  }

  .paperHomeGrah {
    margin: 0;
  }

  .barchart-home {
    max-width: 100px;
  }

  .first-grid {
    margin-top: 20px !important;
  }
}

/*Medium devices (tablets, 768px and up)*/
@media (max-width: 768px) {
  /* .container-login-form {
    margin-top: 20px;
  } */

  #compute-image {
    display: none;
  }

  #login-text-info {
    margin-top: 20px;
  }

  .text-muted {
    display: none;
  }

  /* .container-login-form {
    align-items: flex-start;
  } */
}

/*Large devices (desktops, 992px and up)*/
@media (max-width: 992px) {
  #compute-image {
    width: 230px;
  }

  /* .container-login-form {
    margin-top: 20px;
  } */
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (max-width: 1200px) {
  #compute-image {
    width: 230px;
    margin-left: -290px;
  }

  #login-text-info {
    font-size: 2rem;
    font-weight: bolder;
  }

  /* .container-login-form {
    margin-top: 40px;
  } */
}

.table-item-reports {
  -webkit-transition: background 0.26s;
  transition-property: background;
  transition-duration: 0.26s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.table-item-reports:hover {
  background-color: #e9e9e9;
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
  cursor: pointer;
}

.table-item-reports-active {
  background-color: #e9e9e9;
}

.box-tab-teams {
  padding: 20px 0 !important;
}

/**/

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  background-color: #e3e3e3;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 10px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.tableheader {
  background-color: #fff !important;
}

@media (max-width: 641px) {
  .tableheader {
    font-size: 12px !important;
  }
}

.tablecell-header {
  font-family: "Livvic", sans-serif !important;
  font-size: 18px !important;
  margin: 0px !important;
  text-transform: lowercase;
  text-align: left !important;
  color: #006660 !important;
}

#lockeredit-head-table-sm__spacing {
  padding-right: 25px !important;
}

.tablecell {
  font-size: 14px !important;
  text-align: left !important;
  margin-left: 4px !important;
}

@media (max-width: 641px) {
  .tablecell {
    font-size: 12px !important;
    padding: 6px 2px !important;
  }
}

@media (max-width: 641px) {
  .tablecell-header {
    font-size: 18px !important;
    margin: 0px !important;
    text-transform: lowercase;
  }
  .tablecell-header__action {
    font-size: 12px !important;
  }
  .tablecell-header__team-status {
    padding-right: 10px !important;
  }
  .tablecell-header__team-status-lockertable {
    padding-right: 20px !important;
  }
  .tablecell-header__team-status-deliverytable {
    padding-right: 5px !important;
  }
  .tablecell-header__team-compart-deliverytable {
    padding-right: 34px !important;
  }
  .tablecell-header__initial-table {
    padding-left: 5px !important;
  }
  .tablecell-header__delivery-action {
    padding-left: 18px !important;
  }
}

.td-max {
  max-width: 120px;
}

@media (max-width: 641px) {
  .th-delivery,
  .td-delivery {
    display: none !important;
  }

  .MuiTablePagination-caption-650 {
    display: none;
  }
}

@media (max-width: 641px) {
  .td-business-simple-table {
    display: none !important;
  }
}

@media (max-width: 641px) {
  #business-edit-sm__select div:nth-child(2) {
    margin: 0;
    padding: 10px 0;
  }
  .business-edit-sm__cep {
    display: flex;
    flex-direction: column;
    margin-top: 8px !important;
  }
  .business-edit-sm__cep-input {
    max-width: 100% !important;
    padding: 0px 14px 0 0 !important;
  }
}

@media (max-width: 641px) {
  .tbody-business-simple-table {
    display: block;
  }
}

@media (max-width: 641px) {
  #head-user {
    font-size: 14px;
    font-family: "Livvic", sans-serif;
  }
}

@media (max-width: 641px) {
  #table-sm__svg button:nth-child(1) {
    padding: 4px;
    font-size: 9px;
  }
  #table-sm__svg button:nth-child(2) {
    min-width: 25px !important;
  }
}

.table-footer tr td span {
  font-size: 12px !important;
}
.table-footer tr td div {
  font-size: 12px !important;
}

@media (max-width: 641px) {
  .table-footer {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .table-footer tr td div {
    padding: 0 !important;
    margin: 0 !important;
  }
  .table-footer tr td div svg {
    margin-right: 5px;
  }
  .table-footer tr td span:nth-child(2) {
    display: none !important;
  }
}

@media (max-width: 641px) {
  #main-reponsive {
    padding: 24px 10px;
  }
  #main-reponsive h3 {
    font-size: 22px !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: bold;
  }
}

.form-colaboradore-sm__cpf {
  margin: 0 !important;
  padding: 0;
  display: flex;
}

.form-colaboradore-sm__profile {
  margin-left: 8px !important;
  display: flex;
}

@media (max-width: 641px) {
  .action-form-create-sm {
    max-width: 100%;
  }
  .form-colaboradore-sm {
    padding: 0 !important;
    display: flex;
  }
  .form-colaboradore-sm__profile {
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
  }
  .form-colaboradore-sm__cpf {
    padding-top: 0 !important;
    display: flex;
    padding-left: 0 !important;
  }
  #form-colaboradore-sm {
    padding: 0 !important;
    display: flex;
  }
}

@media (max-width: 641px) {
  #team-table-businessedit-sm {
    min-width: 0;
  }
  #team-table-businessedit-sm__overflow {
    overflow-x: none !important;
  }
}

@media (max-width: 641px) {
  #table-locker-sm {
    margin: 0 -25px;
  }
  #container-form-locker-sm {
    width: 98vw !important;
    margin: -6px -34px !important;
    padding: 0 !important;
  }
}

@media (max-width: 641px) {
  #content-block-report-sm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #block-report-sm {
    width: 80%;
    margin: 10px 0;
    border-radius: 30px;
  }
}

.configuration-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 16;
}
.configuration-content svg {
  width: 50px;
  height: 50px;
}
.configuration-content h2 {
  font-size: 36px;
  font-weight: bold;
  color: #4a4b4a;
  font-family: Montserrat, sans-serif;
}
.configuration-content span {
  font-family: "Livvic", sans-serif !important;
  font-size: 16px;
}

.configuration-content__information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 14px;
  margin-top: 20px;
  padding: 20px;
  padding-left: 10px;
}

.configuration-content__information-div {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-content: center;
  align-items: flex-start;
}

.configuration-content__information-div div {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.configuration-content__information {
  font-size: 16px;
  font-weight: bold;
  /* text-transform: uppercase; */
  font-family: "Livvic", sans-serif;
}

.configuration-content__information-div strong {
  color: #1b301b;
  font-size: 12px;
  font-family: "Livvic", sans-serif !important;
}
.configuration-content__information-div small {
  font-size: 14px;
  color: #1b301b;
  font-family: "Livvic", sans-serif;
}

@media (max-width: 641px) {
  #home-content-sm {
    padding: 24px 0 !important;
  }
  .home-content-sm__grafic {
    margin: -22px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: stretch !important;
  }
  .home-content-sm__location-time {
    margin: 0px 0 -60px !important;
    padding: 0 !important;
  }
  .home-content-sm__chart {
    width: 100% !important;
    margin-right: 35px;
  }
  #home-content-sm__performance {
    margin: 0 !important;
    padding: 0;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .home-content-sm__lockes {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .home-content-sm__lockes-1 {
    padding: 0 !important;
  }
  .home-content-sm__count-deliveries {
    font-family: Montserrat, sans-serif !important;
  }
  .home-sm__svg-seta svg:last-child {
    right: 10px;
  }
  .home-sm__svg-seta svg:first-child {
    right: 0;
  }
  .home-content-sm__locacao-feita {
    display: flex;
    padding: 0;
    font-size: 100%;
  }
}

.head-sm__name p {
  margin: 0 !important;
}

@media (max-width: 641px) {
  .head-sm {
    background: rgba(0, 0, 0, 0.54);
  }
  .head-sm__name p {
    display: none;
  }
  .head-sm__name svg {
    color: #fff;
  }
  #head-user {
    color: #fff;
  }
  #head-sm__title {
    color: #fff;
    z-index: 1000;
  }
}

@media (max-width: 641px) {
  .teams-sm__pesquisa {
    display: none;
  }
  .teams-sm__criar {
    height: 20px;
  }
  #teams-sm__table {
    margin-top: 0;
  }
  .teams-sm__button {
    margin-top: 20px !important;
  }
  .team-edit-sm__status {
    padding: 0 14px 0 0 !important;
    width: 100%;
  }
  #team-edit-sm__button-salvar {
    margin: 0 !important;
  }
}

@media (max-width: 641) {
  .container-sm__criar-locacao button {
    margin-top: 20px !important;
  }
  #title-sm__h3 h3 {
    font-size: 10px !important;
    font-family: Montserrat, sans-serif !important;
  }
}

@media (max-width: 641) {
  #siderbar-sm div {
    margin: 0 60px !important;
  }
  #sidebar-sm__close {
    color: #fff !important;
    margin: 10px;
  }
}

@media (max-width: 641) {
  #team-create-sm__h5 {
    display: none;
  }
}

@media (max-width: 641) {
  .lockers-sm__button-pesquisar {
    display: flex;
    width: 100%;
    margin-left: -32px;
  }
}

@media (max-width: 641) {
  #not-found-sm {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

@media (max-width: 641) {
  .lockeredit-tab-sm {
    width: 92vw;
  }
  #lockeredit-tab-content-sm {
    width: 92vw;
  }
  .marginButton {
    margin-left: 0px;
  }
}

.locker-edit-sm__container {
  display: flex !important;
  flex-direction: column !important;
}

.marginButton {
  margin-left: -10px;
}
